@import "../../../assets/scss/variables.scss";

%header-info-text {
  letter-spacing: 0px;
  color: $c-white;
  font-family: $f-medium;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
}

.header {
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 120px;

  .fixed_header {
    position: fixed;
    width: 100%;
    z-index: 200;
  }

  @media (max-width: $d-header-mobilewidth) {
    display: none;
  }

  &__info {
    height: 36px;
    width: 100%;
    display: flex;
    background-color: $c-main-red;

    a {
      text-decoration: none;
      color: #ffffff;
    }

    &__left {
      display: flex;
      flex: 1;
      padding-left: 139px;
      justify-content: flex-start;
      align-items: center;
      cursor: default;

      @media (max-width: $d-xlarge) {
        padding-left: 90px;
      }

      @media (max-width: $d-large) {
        padding-left: 70px;
      }

      @media (max-width: $d-medium) {
        padding-left: 50px;
      }

      @media (max-width: $d-small) {
        padding-left: 30px;
      }

      &__email {
        @extend %header-info-text;
      }
    }

    &__right {
      display: flex;
      flex: 1;
      padding: 8px 139px 8px 0;
      justify-content: flex-end;
      align-items: center;
      cursor: default;

      @media (max-width: $d-xlarge) {
        padding-right: 90px;
      }

      @media (max-width: $d-large) {
        padding-right: 70px;
      }

      @media (max-width: $d-medium) {
        padding-right: 50px;
      }

      @media (max-width: $d-small) {
        padding-right: 30px;
      }

      &__contact {
        @extend %header-info-text;
      }
    }
  }

  &__main {
    height: 86px;
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: $c-white;

    &__left {
      display: flex;
      flex: 1;
      justify-content: flex-start;
      align-items: center;
      padding-left: 140px;

      @media (max-width: $d-xlarge) {
        padding-left: 90px;
      }

      @media (max-width: $d-large) {
        // padding-left: 60px;
        padding-left: 70px;
      }

      @media (max-width: $d-medium) {
        padding-left: 40px;
      }

      @media (max-width: $d-small) {
        padding-left: 30px;
      }

      & img {
        cursor: pointer;
      }
    }

    &__right {
      display: flex;
      flex: 1;
      align-items: center;

      &__productsNumber {
        margin-left: -22px;
        margin-top: -20px;
        margin-right: 9px;
        height: 20px;
        width: 20px;
        border-radius: 100px;
        background-color: $c-main-red;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &__number {
          font-size: 12px;
          color: $c-white;
          font-family: $f-medium;
          text-align: center;
        }
      }

      & ul {
        display: flex;
        height: 100%;
        width: 100%;
        padding-right: 139px;
        margin: 0;

        @media (max-width: $d-large) {
          padding-right: 40px;
          padding-left: 10px;
        }

        @media (max-width: $d-medium) {
          padding-right: 30px;
        }

        @media (max-width: $d-small) {
          padding-right: 10px;
        }
      }
    }
  }
}

.headerMobile {
  @media (max-width: $d-header-mobilewidth) {
    display: flex;
  }

  display: none;
  flex-direction: column;
  width: 100%;

  &__info {
    padding: 10px;
    width: 100%;
    display: flex;
    background-color: $c-main-red;
    flex-direction: column;

    a {
      text-decoration: none;
      color: #ffffff;
    }

    &__left {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;

      &__email {
        @extend %header-info-text;
      }
    }

    &__right {
      display: flex;
      flex: 1;
      margin-top: 10px;
      justify-content: center;
      align-items: center;

      &__contact {
        @extend %header-info-text;
      }
    }
  }

  &__main {
    height: 86px;
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: $c-white;

    &__left {
      display: flex;
      flex: 1;
      justify-content: flex-start;
      align-items: center;
      padding-left: 20px;

      & img {
        cursor: pointer;
      }
    }

    &__right {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: flex-end;
      padding-right: 20px;

      &__menuIcon {
        font-size: x-large;
        position: relative !important;
        top: 0;
        z-index: -1;

        &--display {
          display: flex;
          position: relative !important;
        }

        &--hide {
          display: none;
        }
      }

      &__menuIconX {
        &--display {
          display: flex;
        }

        &--hide {
          display: none;
        }
      }

      &__navLinks {
        width: 100%;
        right: 0;
        display: flex;
        position: absolute;
        top: 146px;
        max-height: auto;
        padding: 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        z-index: 100;

        &__top {
          height: 350px;
          background-color: $c-white;

          &__productsNumber {
            margin-left: -20px;
            height: 20px;
            margin-right: 9px;
            margin-top: -20px;
            width: 20px;
            border-radius: 100px;
            background-color: $c-main-red;
            display: flex;
            justify-content: center;
            z-index: 10;
            align-items: center;

            &__number {
              font-size: 12px;
              color: $c-white;
              font-family: $f-medium;
              text-align: center;
            }
          }

          & ul {
            width: 100%;
            display: flex;
            height: 100%;
            flex-direction: column;
            padding: 0;
            margin: 0;
            border-top: 0.5px solid $c-background-main;
          }
        }

        &__bottom {
          background-color: black;
          flex: 1;
          opacity: 0.5;
          display: flex;
        }
      }
    }
  }
}

.NavLinkItem {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 29px;
  transition: border 0.5s ease;
  box-sizing: border-box;

  @media (max-width: $d-medium) {
    margin-right: 22px;
  }

  @media (max-width: $d-small) {
    margin-right: 16px;
  }

  &__icon {
    color: $c-text-gray;
    font-size: x-large;
    margin-right: 9px;
    cursor: pointer;

    &--red {
      color: $c-main-red;
      transition: all 0.2s linear;
    }
  }

  &__text {
    @extend %header-info-text;
    font-family: $f-bold;
    white-space: nowrap;
    color: $c-text-gray;
    cursor: pointer;
    transition: color 0.5s ease;
    height: 100%;
    display: flex;
    align-items: center;

    @media (max-width: $d-medium) {
      font-size: 13px;
    }

    &--red {
      color: $c-main-red;
      transition: all 0.2s linear;
    }

    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
      text-decoration: none;
    }

    &:hover {
      color: $c-text-gray;
    }

    &--active {
      color: $c-main-red;

      &:hover {
        color: $c-main-red;
      }
    }
  }
}
