@import '../../../assets/scss/variables.scss';

.clickableMessage {
    width: 100%;
    height: 100%;
    padding-left: 14px;
    display: flex;
    align-items: center;
    padding-top: 11px;
    padding-bottom: 11px;

    &__leftLine {
        height: 100%;
        width: 4px;
        border-radius: 100px;
        background-color: #2ba57b;
    }

    &__checkIcon {
        color: #2ba57b;
        font-size: xx-large;
        margin-top: 4px;

        &--error {
            color: $c-error-text;
        }
    }

    &__title {
        color: $c-text-black;
        font-size: 15px;
        font-family: $f-regular;

        @media (max-width:$d-mobilewidth) {
            font-size: 13px;
        }
    }

    &__content {
        color: #a9abaf;
        font-family: $f-regular;
        font-size: 15px;

        @media (max-width:$d-mobilewidth) {
            font-size: 13px;
        }
    }

    &__column {
        height: 100%;
        width: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.toast-bodyClassName-success {
    border: 1px solid $c-background-dashboard !important;
    background-color: white;
    height: 90px;

    box-shadow: 0px 30px 80px rgba(0, 0, 0, 0.3) !important;
    border-radius: 10px;
    padding: 0;

    @media (max-width:$d-mobilewidth) {
        border-radius: 0;
    }

    &__body {
        margin: 0;
        border-radius: 10px;
        display: flex;
        align-items: center;

        @media (max-width:$d-mobilewidth) {
            border-radius: 0;
        }

        &__closeButton {
            color: #a9abaf;
            font-size: larger;
            align-self: center;
            margin: auto;
            margin-right: 14px !important;
        }

        &__progress {
            background-color: $c-background-main;
        }
    }
}