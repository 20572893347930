
  .cookie_text{
    width: 90%;
    //display: flex;
  }

  .button_style{
    display: flex;
    justify-content: end;
    width: 100%;
  }
