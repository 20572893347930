@import './variables.scss';

.p-mobile-left-10 {
    @media (max-width:$d-mobilewidth) {
        padding-left: 10px;
    }

}

.p-mobile-horizontal-10 {
    @media (max-width:$d-mobilewidth) {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}

.p-right-10 {
    padding-right: 10px;
}

.p-right-9 {
    padding-right: 9px;
}

.p-right-8 {
    padding-right: 8px;
}

.p-right-7 {
    padding-right: 7px;
}

.p-right-6 {
    padding-right: 6px;
}

.p-right-5 {
    padding-right: 5px;
}

.p-right-4 {
    padding-right: 4px;
}

.p-right-3 {
    padding-right: 3px;
}

.p-right-2 {
    padding-right: 2px;
}

.p-left-10 {
    padding-left: 10px;
}


.p-left-8 {
    padding-left: 8px;
}

.p-left-7 {
    padding-left: 7px;
}

.p-left-6 {
    padding-left: 6px;
}

.p-left-5 {
    padding-left: 5px;
}

.p-left-4 {
    padding-left: 4px;
}

.p-left-3 {
    padding-left: 3px;
}

.p-horizontal-0-mobile {
    @media (max-width:$d-mobilewidth) {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.p-horizontal-10 {
    padding-left: 10px;
    padding-right: 10px;
}

.p-horizontal-8 {
    padding-left: 8px !important;
    padding-right: 8px !important;
}

.p-10 {
    padding: 10px;
}