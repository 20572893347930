// margins top
.m-top-15 {
    margin-top: 15px;
}

.m-top-14 {
    margin-top: 14px;
}

.m-top-13 {
    margin-top: 13px;
}

.m-top-12 {
    margin-top: 12px;
}

.m-top-8 {
    margin-top: 8px;
}


.m-top-9 {
    margin-top: 9px;
}

.m-top-10 {
    margin-top: 10px;
}

.m-top-6 {
    margin-top: 6px;
}

.m-top-5 {
    margin-top: 5px;
}

.m-top-4 {
    margin-top: 4px;
}

.m-top-3 {
    margin-top: 3px;
}

.m-top-17 {
    margin-top: 17px;
}

.m-top-16 {
    margin-top: 17px;
}

.m-top-20 {
    margin-top: 20px;
}

.m-top-30 {
    margin-top: 30px;
}


.m-top-25 {
    margin-top: 25px;
}

.m-top-18 {
    margin-top: 18px;
}

.m-top-2 {
    margin-top: 2px;
}

.m-top-19 {
    margin-top: 19px;
}

.m-top-16 {
    margin-top: 16px;
}

.m-top-24 {
    margin-top: 24px;
}

.m-top-47 {
    margin-top: 47px;
}

// margins  left
.m-left-1 {
    margin-left: 1px;
}

.m-left-2 {
    margin-left: 2px;
}

.m-left-3 {
    margin-left: 3px;
}

.m-left-4 {
    margin-left: 4px;
}

.m-left-5 {
    margin-left: 5px;
}

.m-left-6 {
    margin-left: 6px;
}

.m-left-7 {
    margin-left: 7px;
}

.m-left-8 {
    margin-left: 8px;
}

.m-left-10 {
    margin-left: 10px;
}

.m-left-15 {
    margin-left: 15px;
}

.m-left-14 {
    margin-left: 14px;
}

.m-left-13 {
    margin-left: 13px;
}

.m-left-16 {
    margin-left: 16px;
}

.m-left-17 {
    margin-left: 17px;
}

.m-left-20 {
    margin-left: 20px;
}

.m-left-12 {
    margin-left: 12px;
}

.m-left-13 {
    margin-left: 13px;
}

.m-left-14 {
    margin-left: 14px;
}




//margins right
.m-right-50 {
    margin-left: 50px;
}

.m-right-10 {
    margin-right: 10px;
}

.m-right-30 {
    margin-right: 30px;
}

.m-right-20 {
    margin-right: 20px;
}

.m-left-10-mobile {
    @media (max-width:$d-mobilewidth) {
        margin-left: 10px;
    }
}

.m-right-10-mobile {
    @media (max-width:$d-mobilewidth) {
        margin-right: 10px;
    }
}

.m-bottom-6 {
    margin-bottom: 6px;
}