@import '../../../assets/scss/variables.scss';

%header-info-text {
    letter-spacing: 0px;
    color: $c-white;
    font-family: $f-medium;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
}

.footer {
    width: 100%;
    border: 1px solid $c-main-red;
    height: 340px;
    background-color: $c-main-red;
    justify-content: space-between;
    border-radius: 8px;
    display: flex;

    @media (max-width:$d-footer-mobile) {
        display: none;
    }

    &__left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 39px 0px 44px 60px;
        border-radius: 8px;
        background-color: $c-main-red;

        &__textContent {
            color: $c-white;
            letter-spacing: 0px;
            font-size: 18px;
            font-family: $f-medium;
            text-align: left;
            margin: 0;
            margin-top: 20px;

            @media (max-width:1260px) {
                margin-top: 16px;
                font-size: 14px;
            }

            @media (max-width:$d-large) {
                margin-top: 14px;
                font-size: 12px;
            }

            @media (max-width:$d-medium) {
                margin-top: 12px;
                font-size: 13px;
            }

            @media (max-width:$d-small) {
                margin-top: 12px;
                font-size: 13px;
            }
        }

        &__textTitle {
            font-size: 36px;
            font-family: $f-bold;
            color: $c-white;
            text-align: left;
            margin: 0;

            @media (max-width:1260px) {
                font-size: 30px;
            }

            @media (max-width:1150px) {
                font-size: 26px;
            }

            @media (max-width:$d-large) {
                font-size: 26px;
            }

            @media (max-width:$d-medium) {
                font-size: 24px;
            }

            @media (max-width:$d-small) {
                font-size: 22px;
            }
        }

        &__icons {
            margin-top: 22px;

            &__faIcon {
                color: $c-white;
                font-size: 30px;
                margin-right: 14px;

                @media (max-width:$d-large) {
                    font-size: 28px;
                }

                @media (max-width:$d-medium) {
                    font-size: 24px;
                }

                @media (max-width:$d-small) {
                    font-size: 20px;
                }
            }
        }
    }

    &__right {
        height: 100%;
        display: flex;
        background-color: $c-white;
        width: 40%;
        background-color: white;
        background-image: url('../../../assets/images/white_footer.png');
        border-radius: 6px;
        justify-content: center;
        align-items: center;
        padding-left: 0;
        padding-right: 20px;
        justify-content: center;

        &__image {
            border: 1px solid inherit;
            height: 273px;
            margin-left: -30px;
        }
    }
}

.footerMobile { 
    width: 100%;
    padding-top: 10px;
    height: auto;
    background-color: $c-main-red;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    padding-bottom: 65px;
    

    @media (max-width:$d-footer-mobile) {
        display: flex;
    }

    &__textContent {
        color: $c-white;
        letter-spacing: 0px;
        font-size: 14px;
        font-family: $f-medium;
        text-align: center;
        margin: 0;
        margin-top: 14px;
    }

    &__textTitle {
        font-size: 26px;
        font-family: $f-bold;
        color: $c-white;
        text-align: center;
        margin: 0;
        margin-top: 14px;
    }

    &__icons {
        margin-top: 16px;

        &__faIcon {
            color: $c-white;
            font-size: 22px;
            margin-right: 14px;
        }
    }
}
