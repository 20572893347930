@import '../../assets/scss/variables.scss';

.supervivaText {
    font-size: 24px;
    color: $c-main-red;
    font-family: $f-bold;
    cursor: pointer;
}

.label {
    font-size: 12px;
    color: $c-text-gray-dark-4d;
    font-family: $f-bold;
    height: 17px;
}

.registerText {
    font-size: 12px;
    font-family: $f-bold;
    color: $c-main-red;
    text-align: center;
    display: flex;
    justify-content: center;
    cursor: pointer;

    &:hover {
        color: $c-main-red;
    }
}

.loginPage {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;


    &__card {
        width: 280px;
        min-height: 354px;
        height: auto;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #FFFFFF;
        border-radius: 10px;
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 30px;
        padding-top: 37px;

        &__supervivaText {
            @extend .supervivaText;
        }

        &__label {
            @extend .label;
        }

        &__registerText {
            @extend .registerText;
            cursor: pointer;
        }

        &__forgotPassword {
            cursor: pointer;
            font-family: $f-semibold;
            color: $c-text-gray-dark-4d;
            font-size: 12px;
            text-align: center;
        }
        &__icon{
            color: #444552;
            font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }
}

.loginPage__card__footer__texts{
    text-align: center;
}

.loginPage__card__footer__secondtexts{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.loginPage__card__terms__and__conditions{
    font-size: 10px;
    width: 100%;
    align-self: center;
    margin-top: 10px;
    color: #4D4F5C;
    cursor: pointer;
    font-weight: 500;
    text-align: center;
}

.registerPage__card__bottom__texts{
    display: flex;
    width: 100%;
}

.registerPage__card__terms__and__conditions{
    font-size: 12px;
    font-family: "Poppins-Bold";
    width: 100%;
    align-self: center;
    margin-top: 17px;
    color: #4D4F5C;
    cursor: pointer;
}

.registerPage {
    @extend .loginPage;

    &__card {
        @extend .loginPage__card;
        width: 500px;
        height: auto;
        max-height: auto;
        min-height: 360px;
        max-width: 100%;

        @media (max-width:481px) {
            justify-content: center;
        }

        &__supervivaText {
            @extend .supervivaText;
        }

        &__row {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            @media (max-width:481px) {
                justify-content: center;

                & div {
                    width: 100%;

                    & .inputContainer {
                        width: 100% !important;
                    }
                }
            }
        }

        &__columnCenter {
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
        }
    }
}