@import '../../assets/scss/variables.scss';

.loadingPage {
    width: 100%;
    background-color: $c-background-main;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
        height: 180px;
    }
}

ol {
  counter-reset: item;
}

ol > li {
  counter-increment: item;
}

ol ol > li {
  display: block;
}

ol ol > li:before {
  content: counters(item, ".") ". ";
  margin-left: -20px;
}
.tos-container {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .tos-content {
    height: calc(100vh - 80px);
    width: 100%;
    padding:50px 20px;
    overflow-y: scroll;

    &__container {
      margin:0 auto;
    }

    &__text {
      width: 100%;

      h1 {
        display: block;
        margin-bottom: 32px;
        font-family: "Poppins-Bold";
        font-size: 30px;
        color: #EE0011;
      }

      h2 {
        display: block;
        margin: 49px 0 22px;
        font-family: "Poppins-Bold";
        font-size: 20px;
        color: #707070;
      }

      p {
        display: block;
        font-family: "Poppins-Regular";
        font-size: 15px;
      }
    }
  }
