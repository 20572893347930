@import '../../../assets/scss/variables.scss';

.inputContainer {
    height: 40px;
    width: 240px;
    min-width: auto;
    border: 1px solid #DBDBDB;
    border-radius: 10px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    display: flex;
    align-items: center;
    padding: 10px 0 10px 0px;
    padding-right: 0 !important;

    &__input {
        display: flex;
        flex: 1;
        padding-left: 13px;
        width: 100%;
        outline: none;
        border: none;
        color: #4D4F5C;
        font-family: $f-medium;
        text-overflow: ellipsis;
        font-size: 12px;
        background-color: inherit;

        &:-webkit-autofill {

            &,
            &:hover,
            &:focus,
            &:active {
                background-color: white !important;
            }
        }

        &:-webkit-autofill,
        &:focus:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 100px white inset;
        }


        &::placeholder {
            color: $c-text-gray;
            font-family: $f-medium;
            opacity: 0.4;
            font-size: 12px;
        }
    }

    &__eyeButton {
        height: 100%;
        padding-left: 10px;
        padding-right: 10px !important;
        display: flex;
        justify-content: center;
        align-items: center;

        &__icon {
            font-size: large;
            color: #4D4F5C;
        }
    }
}

.inputDisabled {
    background-color: hsl(0, 0%, 95%) !important;

    & input {
        color: hsl(0, 0%, 60%);
    }
}

.checkBox {
    display: flex;
    justify-content: center;
    align-items: center;

    &__icon {
        font-size: x-large;
        transition: all .3s linear;

        &--checked {
            color: $c-main-red;
            transition: all .3s linear;
        }
    }
}