@import '../../../assets/scss/variables.scss';

.cartContainer {
    height: 100vh;
    width: 100%;
    display: flex;
    position: absolute;
    z-index: 300;

    &__left {
        background: #242020 0% 0% no-repeat padding-box;
        opacity: 0.3;
        display: flex;
        flex: 1;
    }

    &__right {
        width: 370px;
        height: 100%;
        background-color: $c-background-main;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;

        &__header {
            height: 50px;
            width: 100%;
            background-color: $c-main-red;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px 20px 15px 20px;

            &__text {
                font-family: $f-medium;
                font-size: 14px;
                color: $c-white;

                &--bold {
                    font-family: $f-bold;
                }
            }

            &__icon {
                color: $c-white;
                font-size: x-large;
                cursor: pointer;
            }
        }

        &__body {
            display: flex;
            flex-direction: column;
            flex: 1;
            position: relative;
            height: auto;
            overflow-y: scroll;
            overflow-x: hidden;
            padding-bottom: 50px;
            -webkit-overflow-y: auto;
            -webkit-overflow-scrolling: auto;
            white-space: nowrap;
            background-color: white;

        }

        &__bottom {
            height: auto;
            width: 100%;
            background-color: white;

            &__row {
                display: flex;
                justify-content: space-between;
                padding-top: 15px;

                padding-left: 20px;
                padding-right: 20px;

                &__text {
                    font-family: $f-semibold;
                    font-weight: 600;
                    font-size: 14px;
                    color: $c-text-black;
                }
            }

            &__rowBottom {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 20px 20px 20px;
                flex-wrap: wrap;

                &__text {
                    color: $c-main-red;
                    font-family: $f-medium;
                    font-size: 14px;
                    margin-top: 20px;
                    cursor: pointer;
                }
            }
        }
    }
}

.productInCartContainer {
    padding: 20px 20px 20px 20px;
    display: flex;
    background-color: $c-white;
    min-height: 110px;
    width: 100%;

    &__row {
        display: flex;
        height: 100%;
        flex: 1;
        overflow: hidden;
    }

    &__left {
        width: auto;

        & img {
            border-radius: 8px;
            border: 1px solid #DBDBDB;
            width: 70px;
            height: 70px;
            object-fit: contain;
            max-height: 70px;
        }
    }

    &__right {
        padding-left: 13px;
        display: flex;
        flex-direction: column;
        flex: 1;
        max-width: 230px;


        &__name {
            font-family: $f-semibold;
            font-weight: 600;
            text-align: left;
            font-size: 18px;

            max-lines: 1;
            line-clamp: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &__rowInputs {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
        }
    }

    &__xContainer {
        width: 30px;
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: flex-start;
        // padding-left: 13px;


        &__icon {
            font-size: x-large;
            font-family: $f-bold;
            color: $c-text-black;
            cursor: pointer;
            padding-top: 0 !important;
            font-weight: 700;
            margin-top: -4px;

        }
    }
}

.cartInPayment {
    height: 682px;
    width: 370px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__body {
        display: flex;
        flex: 1;
        overflow-y: scroll;
        flex-direction: column;
        padding-bottom: 20px;
    }

    &__bottom {
        height: auto;
        min-height: 170px;
        width: 100%;

        &__row {
            display: flex;
            width: 100%;
            height: auto;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
        }

        &__totalDiscount {
            font-family: $f-medium;
            font-size: 14px;
            color: $c-text-black;

            &--semiBold {
                font-family: $f-semibold;
                color: #EE0011;
            }
        }

        &__total {
            font-family: $f-semibold;
            font-size: 18px;
            color: $c-text-black;
            font-weight: 600;
        }

        &__discountCode {
            color: $c-main-red;
            font-size: 14px;
            font-family: $f-medium;
            cursor: pointer;
        }
    }
}

.productInPaymentCart {
    height: auto;
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    min-height: 70px;

    &__imgContainer {
        height: 70px;
        width: 70px;
        min-height: 70px;
        min-width: 70px;
        padding: 7px 4px 7px 4px;

        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #DBDBDB;
        border-radius: 8px;
        opacity: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        & img {
            height: 100%;
            max-height: 56px;
            width: 100%;
            object-fit: cover;
        }
    }

    &__infoContainer {
        display: flex;
        width: 100%;
        padding-left: 13px;
        justify-content: space-between;

        &__column {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding-top: 6px;
        }

        &__quantity {
            font-size: 12px;
            font-family: $f-medium;
            color: $c-text-black;
            text-align: left;

            &__oldPrice {
                font-family: $f-medium;
                font-weight: normal;
                font-size: 11px;
                margin-left: 4px;
                text-decoration-line: line-through;
                align-items: flex-end;
                color: $c-main-red;
            }
        }

        &__name {
            font-family: $f-semibold;
            font-size: 16px;
            color: $c-text-black;
            font-weight: 600;
        }

        &__totalContainer {
            align-self: center;

            &__price {
                font-family: $f-semibold;
                font-size: 18px;
                color: $c-text-black;
                font-weight: 600;
                align-self: center;
            }
        }
    }
}

.horizontalLine {
    width: 100%;
    height: 1px;
    background-color: #DBDBDB;
}

.p-horizontal-20 {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
}

.buttonDisabled {
    opacity: 0.3;
    cursor: not-allowed !important;
}



.productInCart-enter {
    opacity: 0;
}

.productInCart-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.productInCart-exit {
    opacity: 1;
}

.productInCart-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
}
