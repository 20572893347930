$base-color: #c6538c;
$border-dark: rgba($base-color, 0.88);

//colors
//c for color
$c-main-red:#EE0011;
$c-white:#FFFFFF;
$c-text-black:#292929;
$c-text-gray:#707070;
$c-background-main:#F7F7F7;
$c-background-dashboard:#F0F0F0;
$c-text-gray-dark-4d:#4D4F5C;
$c-text-nobel:#9F9F9F;
$c-border-gray:#DBDBDB;
$c-error-text : #FF0033;

// font families 
// f for fonts
$f-medium:'Poppins-Medium';
$f-regular:'Poppins-Regular';
$f-semibold:'Poppins-SemiBold';
$f-light:'Poppins-Light';
$f-bold:'Poppins-Bold';

//dimensions
$d-mobilewidth : 550px;
$d-mobilewidth-small:350px;
$d-header-mobilewidth:730px;
$d-small:750px;
$d-medium:900px;
$d-large:1050px;
$d-xlarge:1250px;
$d-footer-mobile:850px;
$d-iPad:1200px;