@import '../../../assets/scss/variables.scss';

.modalBackground {
    background: rgba(0, 0, 0, .25);
}

.modalSize {
    height: 100vh;
    width: 100%;
}

.deleteConfirmation {
    display: flex;
    justify-content: center;
    align-items: center;

    @extend .modalSize;
    @extend .modalBackground;

    &__content {
        height: 300px;
        width: 400px;
        display: flex;
        border-radius: 10px;
        background-color: white;
        padding: 10px;
        flex-direction: column;
        justify-content: center;

        &__confirmationText {
            font-family: $f-medium;
            font-size: 20px;
            color: $c-text-black;
            text-align: center;
        }

        &__actions {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 30px;
        }
    }
}

.modalLoading {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 600;

    @extend .modalSize;
    @extend .modalBackground;

    &__content {
        height: 300px;
        width: 300px;
        margin: auto;
        // background: white;
        display: flex;
        border-radius: 10px;
        justify-content: center;
        align-items: center;
        z-index: 500;
    }
}

.addNewAddressModal {
    @extend .modalSize;
    @extend .modalBackground;
}

.authModalFade {
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginModal {
    @extend .modalBackground;
    @extend .modalSize;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    max-width: 100%;

    &__content {
        width: 300px;
        height: auto;
        border-radius: 10px;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 999;
        max-width: 100%;


        &__closeContainer {
            width: 100%;
            max-width: 100%;
            height: auto;
            padding: 10px;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            &__icon {
                font-size: x-large;
                color: $c-text-black;
            }
        }

    }
}

.registerModal {
    @extend .loginModal;

    &__content {
        @extend .loginModal__content;
        width: 500px;
        min-height: 360px;
        max-width: 100%;

        &__closeContainer {
            @extend .loginModal__content__closeContainer;

            &__icon {
                @extend .loginModal__content__closeContainer__icon;
            }
        }
    }
}