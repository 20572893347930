@import '../../assets/scss/variables.scss';

.LinkDashboard {
    width: 100% !important;
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__text {
        font-family: $f-regular;
        font-weight: 100;
        font-style: normal;
        font-size: 16px;
        letter-spacing: 0px;
        color: $c-white;
        text-decoration: none;

        &:focus,
        &:hover,
        &:visited,
        &:link,
        &:active {
            text-decoration: none;
            color: $c-white;
        }

        &--active {
            font-family: $f-bold;
        }
    }

    &__circle {
        width: 10px;
        height: 10px;
        background: #F7F7F7 0% 0% no-repeat padding-box;
        border-radius: 100px;
    }
}

.searchDashboard {
    height: 40px;
    width: 240px;
    min-width: auto;
    border: 1px solid #DBDBDB;
    border-radius: 10px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    display: flex;
    align-items: center;
    padding: 10px 0 10px 16px;
    padding-right: 0 !important;

    &__icon {
        color: #4D4F5C;
    }

    &__input {
        display: flex;
        margin-left: 13px;
        margin-right: 0 !important;
        width: 100%;
        flex: 1;
        outline: none;
        border: none;
        color: #4D4F5C;
        font-family: $f-medium;
        text-overflow: ellipsis;
        font-size: 13px;
        max-width: 100%;
        background-color: inherit;

        &::placeholder {
            color: $c-text-gray;
            font-family: $f-medium;
            opacity: 0.4;
            font-size: 13px;
        }
    }
}

.MuiIconButton-root{
    color: #9F9F9F !important;
}

.dropDownDashboard {
    width: 160px;
    height: 40px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DBDBDB;
    border-radius: 10px;
    padding: 11px 21px 11px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__text {
        font-family: $f-regular;
        font-size: 13px;
        color: $c-text-gray-dark-4d;
        text-align: left;
    }

    &__icon {
        color: $c-text-nobel;
        text-align: right;
    }
}

.textTitle {
    color: $c-text-black;
    text-align: left;
    font-size: 30px;
    font-family: $f-bold;

    @media (max-width:$d-mobilewidth) {
        margin-left: 20px;
    }
}

.inputUploadContainer {
    width: 200px;
    height: 40px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid $c-border-gray;
    background-color: $c-white;
    display: flex;
    justify-content: space-between;

    & .commonButton {
        font-size: 12px;
        font-family: $f-bold;
        justify-content: center;
        align-items: center;
        display: flex;
        text-transform: capitalize;
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
        border-bottom-right-radius: 8px !important;
        border-top-right-radius: 8px!important;
    }

    &__input {
        display: flex;
        flex: 1;
        margin-left: 10px;
        border: none;
        font-family: $f-medium;
        font-weight: normal;
        color: $c-text-gray-dark-4d;
        opacity: 0.4;
        font-size: 13px;
        text-align: center;
        cursor: pointer;
    }
}

.uploadedImage {
    height: 60px;
    width: 60px;
    mix-blend-mode: normal;
    border-radius: 8px;
    border: 1px solid $c-border-gray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 5px 0px 5px;
    margin-right: 10px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    & img {
        max-width: 100%;
        max-height: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 10px;
    }

    &:last-child {
        margin-right: 0;
    }

    &__close {
        height: 16px;
        width: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: flex-end;
        border-radius: 50%;
        background-color: $c-text-nobel;
        margin-top: 5px;
        z-index: 20;

        &__icon {
            color: $c-white;
            font-size: smaller;
        }
    }
}

.paginationButtons {
    height: auto;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    &__button {
        width: 30px;
        height: 30px;
        background: $c-white 0% 0% no-repeat padding-box;
        border: 1px solid $c-border-gray;
        border-radius: 10px;
        margin-left: 4px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.2s linear;

        &--disabled {
            cursor: not-allowed;
            opacity: 0.6;
        }

        &__content {
            color: $c-text-nobel;
        }

        &__numberText {
            font-family: $f-medium;
            font-size: 13px;
            color: $c-text-gray-dark-4d;
            opacity: 0.5;
            text-align: center;

            &--active {
                opacity: 1;
                color: $c-white;
            }
        }

        &--active {
            background: $c-main-red;
            border: 1px solid $c-main-red;
            transition: all 0.2s linear;
        }
    }
}

.statisticCard {
    height: 100px;
    width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 20px;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-right: 20px;
    justify-content: center;
    padding-left: 30px;
    margin-top: 16px;

    &:last-child {
        margin-right: 0px;
    }

    &__divData {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin-left: 15.6px;

        &__number {
            color: $c-main-red;
            text-align: left;
            font-family: $f-semibold;
            font-weight: 600;
            font-size: 28px;
            height: 39px;
            letter-spacing: 0px;
        }

        &__name {
            color: $c-text-gray-dark-4d;
            font-size: 13px;
            font-family: $f-medium;
            font-weight: normal;
            text-align: left;
        }
    }
}

.recentOrderCard {
    display: flex;
    flex: 1;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    height: 92px;
    padding: 6px 30px 6px 28px;
    justify-content: space-between;
    align-items: center;
    width: 49%;
    max-width: 49%;
    min-width: 415px;
    margin-top: 13px;

    @media (max-width:929px) {
        min-width: 100%;
    }

    &__left {
        display: flex;
        flex-direction: column;

        &__name {
            font-family: $f-semibold;
            font-size: 18px;
            font-weight: 600;
            color: $c-text-black;
            text-align: left;
        }

        &__orderId {
            font-family: $f-regular;
            font-size: 13px;
            color: $c-text-gray-dark-4d;
        }
    }

    &__mid {
        display: flex;
        align-items: flex-start;

        &__icon {
            color: $c-main-red;
            font-size: x-large;
        }

        &__location {
            margin-left: 6px;
            font-family: $f-regular;
            font-size: 13px;
            color: $c-text-gray-dark-4d;
            max-height: 40px;
            text-overflow: ellipsis;
            max-width: 153px;
        }
    }

    &__right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__productNumber {
            font-family: $f-bold;
            color: $c-main-red;
            font-size: 28px;
            text-align: center;
        }

        &__productText {
            text-align: center;
            color: $c-main-red;
            font-family: $f-bold;
            font-size: 13px;
        }
    }
}
