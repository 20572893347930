@import '../../assets/scss/variables.scss';

%header-info-text {
    letter-spacing: 0px;
    color: $c-white;
    font-family: $f-medium;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
}

.error-text {
    font-family: $f-medium;
    font-size: 10px;
    color: $c-error-text;
    text-align: left;
}

.containerDiv {
    flex-direction: column;
    height: auto;
    background-color: $c-white;
    border-radius: 10px;
    width: 100% !important;
    margin: 0;

    @media (max-width:$d-mobilewidth) {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}

.productsHeaderContainer {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    &__title {
        font-family: $f-medium;
        font-size: 21px;
        color: $c-text-black;
        letter-spacing: 0px;
        text-align: left;
        margin: 0;

        @media (max-width:$d-mobilewidth) {
            font-size: 18px;
        }
    }

    &__seeAll {
        font-family: $f-bold;
        font-size: 14px;
        letter-spacing: 0px;
        color: $c-main-red !important;
        cursor: pointer;
        text-transform: uppercase;
        margin: 0;
        text-align: right;

        @media (max-width:$d-mobilewidth) {
            font-size: 12px;
        }
    }
}

.commonButton {
    height: 40px;
    width: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    text-transform: uppercase;
    font-family: $f-bold;
    font-size: 14px;
    font-style: normal;
    color: $c-white;
    background-color: $c-main-red;
    cursor: pointer;
    border: none !important;
    outline: none !important;
    letter-spacing: 0px;

    &--white {
        border: 1px solid $c-main-red !important;
        background-color: $c-white;
        color: $c-main-red;
    }
}
.commonButton_offer{
    height: 40px;
    width: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    text-transform: uppercase;
    font-family: $f-bold;
    font-size: 14px;
    font-style: normal;
    color: $c-white;
    background-color: gray;
    cursor: pointer;
    border: none !important;
    outline: none !important;
    letter-spacing: 0px;

    &--white {
        border: 1px solid $c-main-red !important;
        background-color: $c-white;
        color: $c-main-red;
    }
}
.search {
    width: 720px;
    max-width: 80%;
    height: 50px;
    border-radius: 100px;
    align-self: center;
    margin: auto;
    background-color: $c-white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 5px;
    padding-left: 20px;

    @media (max-width:$d-mobilewidth) {
        width: 95%;
        max-width: 95%;
        height: 44px;
    }

    &__leftsearch {
        display: flex;
        flex: 1;
        height: 100%;
        align-items: center;

        &__imgIcon {
            height: 20px !important;
            width: 20px !important;
        }

        &__input {
            margin-left: 10px;
            width: 100%;
            height: 100%;
            display: flex;
            outline: none;
            border: none;
            color: $c-text-gray;
            font-family: $f-medium;
            text-overflow: ellipsis;
            font-size: 14px;

            &::placeholder {
                color: $c-text-gray;
                font-family: $f-medium;
                opacity: 0.5;
                font-size: 14px;

                @media (max-width:$d-mobilewidth) {
                    font-size: 12px;
                }
            }
        }
    }

    &__rightsearch {
        & .commonButton {
            height: 100%;

            @media (max-width:$d-mobilewidth) {
                font-size: 12px;
            }
        }
    }
}

.inputMultiline {

    border-radius: 10px;
    border: 1px solid $c-border-gray;
    background-color: $c-white;
    height: 120px;
    width: 340px;
    resize: none;
    outline: none;
    padding-left: 13px;
    padding-top: 11px;
}

.cateogryCard {
    border-radius: 8px;
    background-color: $c-white;
    display: flex;
    padding-top: 8px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin-right: 16px;
    width: 168px;
    max-width: 168px;
    min-width: 168px;
    height: 100px;
    max-height: 100px;
    min-height: 100px;
    cursor: pointer;


    &--selected {
        border: 2px solid $c-main-red;
    }

    &--notSelected {
        opacity: 0.5;
    }

    & img {
        position: relative !important;
        object-fit: cover;
        max-width: 100px;
        max-height: 50px;
    }
    .categoryTitle{
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    & h3 {
        color: $c-text-black;
        font-family: $f-medium;
        font-size: 14px;
    }
}

.sliderWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    margin: 0;

    &__leftArrow {
        z-index: 20;
        margin-right: -15px;
        transition: all .1s linear;

        @media (max-width:$d-mobilewidth) {
            visibility: hidden;
        }
    }

    &__rightArrow {
        z-index: 20;
        margin-left: -15px;

        @media (max-width:$d-mobilewidth) {
            visibility: hidden;
        }
    }

    &__slider {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        overflow-x: hidden;
        padding: 0;
        scroll-behavior: smooth;
        overflow-y: hidden;
        transition: all 0.5ms ease-in;

        :last-child {
            margin-right: 0 !important;
        }

        @media (max-width:$d-mobilewidth) {
            overflow-x: auto;
        }
    }
}

.plusMinusQuantity {
    display: flex;
    align-items: center;
    height: 30px;
    border-radius: 6px;
    border: 1px solid $c-border-gray;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: space-between;
    max-width: 70px;
    width: 20px;
    min-width: 70px;

    &__icon {
        color: $c-text-black;
        font-size: 14px;
        cursor: pointer;
    }

    &__quantity {
        color: $c-text-black;
        font-size: 14px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 30px;
        border: none;

        &--bold {
            font-weight: 600;
        }
    }
}


.datePickerItem {
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 1;

    & .react-datepicker__portal {
        border: 1px solid red !important;
    }

    &__popperClass {}

    &--row {
        flex-direction: row;
        align-items: center;
    }

    &__label {
        font-size: 12px;
        font-family: $f-bold;
        color: $c-text-gray-dark-4d;
        margin-bottom: 6px;

        &--row {
            margin-bottom: 0px;
            margin-right: 6px;
        }
    }



    & .react-datepicker__close-icon {
        &::after {
            background-color: $c-background-main !important;
            display: flex;
            justify-content: center !important;
            align-items: center !important;
            color: hsl(0, 0%, 60%);
            font-size: 16px;
            padding: 10px;
        }
    }

    &__dateInput {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center !important;
        border-radius: 10px;
        border: 1px solid #DBDBDB;
        padding-left: 10px !important;
        z-index: 0 !important;
        font-family: $f-medium;
        color: $c-text-gray-dark-4d;
        font-size: 12px;




        &::placeholder {
            color: $c-text-gray-dark-4d;
            opacity: 0.4;
            font-size: 13px;
            font-family: $f-medium;
        }
    }
}
