@font-face {
    font-family: 'Poppins-Bold';
    src: local('Poppins-Bold'), url('../fonts/Poppins-Bold.ttf') format("truetype");
}

@font-face {
    font-family: 'Poppins-Medium';
    src: local('Poppins-Medium'), url('../fonts/Poppins-Medium.ttf') format("truetype");
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: local('Poppins-SemiBold'), url('../fonts/Poppins-SemiBold.ttf') format("truetype");
}

@font-face {
    font-family: 'Poppins-Light';
    src: local('Poppins-Light'), url('../fonts/Poppins-Light.ttf') format("truetype");
}

@font-face {
    font-family: 'Poppins-Regular';
    src: local('Poppins-Regular'), url('../fonts/Poppins-Regular.ttf') format("truetype");
}