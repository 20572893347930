@import "variables.scss";

html,
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  scroll-behavior: smooth;
  height: 100vh;
  background-color: $c-background-main;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
label {
  margin: 0;
}

form {
  padding: 0px;
}

a {
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
}

.noStyleButton {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  padding: 0;
  height: auto;
  width: auto;
  margin: 0;
  display: flex;
  justify-content: center;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    outline: 0;
  }

  &__img {
    width: 150px;
  }
}

.allPages {
  padding-top: 20px;
  padding-bottom: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $c-background-main;

  @media (max-width: 1128px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: $d-mobilewidth) {
    padding-left: 10px;
    padding-right: 10px;
  }

  &__content {
    width: 1088px;

    @media (max-width: 1128px) {
      width: 100%;
    }
  }
}

.full-width-center-child {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toastContainerClass {
  width: 500px !important;
  padding: 0 !important;

  @media (max-width: $d-mobilewidth) {
    width: 100% !important;
    margin-right: 0px;
    bottom: 0 !important;
    transform: translateX(0) !important;
  }
}

.Toastify__toast-container--bottom-right {
  @media (max-width: $d-mobilewidth) {
    right: 0 !important;
    bottom: 0 !important;
    padding-bottom: 0 !important;
  }
}

.pointer {
  cursor: pointer;
}

.mobile-full-width {
  @media (max-width: $d-mobilewidth) {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.buttonDisabled {
  opacity: 0.5 !important;
}
