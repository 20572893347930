@import '../../../assets/scss/variables.scss';

.userDashboardNavigator {
    padding-top: 30px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    padding-bottom: 0;

    &__linkContainers {
        width: 720px;
        min-height: 46px;
        height: auto;
        display: flex;
        justify-content: center;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 10px;
        max-width: 100%;
        flex-wrap: wrap;
        margin-bottom: 0;
        padding-left: 0;

        @media (max-width:$d-mobilewidth) {
            flex-direction: column;
        }

        &__linkDiv {
            height: 100%;

            @media (max-width:$d-mobilewidth) {
                height: 50px !important;
                width: 100%;
                display: flex;
                flex-direction: column;
                // align-items: center;

                padding-left: 50px;
                padding-right: 50px;
            }

        }

        &__link {
            display: flex;
            height: 100%;
            width: auto;
            text-align: center;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-family: $f-medium;
            color: $c-text-gray;
            transition: all 0.3ms ease-in;


            &:hover {
                color: $c-main-red;
                font-family: $f-bold;
            }

            &--active {
                color: $c-main-red;
                font-family: $f-bold;
            }
        }

        &__line {
            width: 100%;
            height: 2px;
            // border:1px solid red;

            background-color: inherit;
            margin-top: -2px;

            @media (max-width: $d-mobilewidth) {
                display: none;
            }

            &--active {
                background-color: $c-main-red !important;
            }
        }
    }
}

.m-right-32 {
    margin-right: 32px;

    &:last-child {
        margin-right: 0;
    }
}