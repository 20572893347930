@import '../assets//scss/variables.scss';


.fade-enter {
    opacity: 0.01;
}

.admin_logout{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 60px;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

.route-section {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
}

.transition-group {
    position: relative;
}

.adminLayout {

    width: 100%;
    height: 100vh;
    min-height: 768px;
    display: flex;
    flex: 1;
    background-color: $c-main-red;

    @media (max-width:624px) {
        height: auto;
        height: 100vh;

    }

    &__leftDashboard {
        display: flex;
        flex: 1;
        height: 94%;
        width: 14.41%;
        padding: 46px 33px 40px 30px;
        flex-direction: column;
        align-items: center;
        min-width: 200px;

        @media (max-width:1090px) {
            display: none;
        }

        &__userCredentials {
            display: flex;
            height: fit-content;
            width: 100% !important;
            align-items: center;
            cursor: pointer;

            &__icon {
                color: $c-white;
                font-size: x-large;
            }

            &__text {
                font-weight: $f-medium;
                font-size: 14px;
                color: $c-white;
                letter-spacing: 0px;
                margin-left: 9px;
            }
        }

        &__navLinkContainers {
            display: flex;
            flex: 1;
            width: 100%;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            margin-top: 80px;
        }
    }

    &__leftDashboardMobile {
        width: 260px;
        min-height: 650px;
        margin-left: -260px;
        bottom: 0;
        top: 0;
        background-color: $c-main-red;
        position: absolute;
        flex-direction: column;
        align-items: center;
        padding: 46px 33px 40px 30px;
        z-index: 200;
        transition: margin 400ms;

        @media (max-width:1090px) {
            display: flex;
            transition: all 400ms;
            margin-left: -260px;

            &--visible {
                margin-left: 0;

            }
        }
    }

    &__rightContent {
        display: flex;
        width: 85.59%;
        background: #F0F0F0 0% 0% no-repeat padding-box;
        border-radius: 20px 0px 0px 20px;
        padding: 39px 60px 37px 40px;
        flex-direction: column;
        overflow-y: auto;

        @media (max-width:1090px) {
            padding-top: 0px;
            width: 100%;
            border-radius: 0px 0px 0px 0px;
        }


        @media (max-width:$d-mobilewidth) {
            padding-left: 0px !important;
            padding-right: 0px !important;
            padding-top: 0px;
        }

        &__overlay {
            position: fixed;
            width: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.4);
            z-index: 2;
            cursor: pointer;
        }

        &__menuMobileContainer {
            display: none;
            width: 100%;
            height: auto;

            @media (max-width:1090px) {
                display: flex;
                padding-top: 20px;
                padding-bottom: 10px;

                &__icon {
                    font-size: xx-large;
                    color: $c-text-black;
                }
            }

            @media (max-width:$d-mobilewidth) {
                padding-left: 10px;
            }
        }


    }
}

.mainLayout {
    body {
        min-height: 100vh;
        /* mobile viewport bug fix */
        min-height: -webkit-fill-available;
      }
      
      html {
        height: -webkit-fill-available;
      }

    height: 100%;
    height: -webkit-fill-available;
    
    .fade-enter {
        opacity: 0.01;
    }

    .fade-enter.fade-enter-active {
        opacity: 1;
        transition: opacity 300ms ease-in;
    }

    .fade-exit {
        opacity: 1;
    }

    .fade-exit.fade-exit-active {
        opacity: 0.01;
        transition: opacity 300ms ease-in;
    }

    .route-section {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
    }

    .transition-group {
        position: relative;
    }
}

.authLayout {
    height: 100vh;
    width: 100%;
    background: transparent url('../assets/images/backgrounds/background_auth2.png') 0% 0% no-repeat padding-box;
    background-size: contain;
    transition: all 0.5ms ease;
    background-position: center;

    @media (max-width:1240px) {
        background-size: cover;
    }
}

.userLayout {
    background-color: $c-background-main;
    max-height: auto;
    height: auto;

    &__header {
        background-color: #f01929;
        background-image: url('../assets/images/backgrounds/background_auth2.png');
        height: 250px;
        width: 100%;
        background-repeat: no-repeat;
        background-position: top;
        background-size: 100%;
        opacity: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;
        padding-bottom: 47px;

        &__imageContainer {
            border-radius: 50%;
            padding: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $c-white;
        }

        &__userCredentials {
            font-family: $f-medium;
            color: $c-white;
            font-size: 21px;
            text-align: center;
            margin-top: 13px;
        }

        &__totalAmount {
            font-family: $f-bold;
            font-size: 13px;
            color: $c-white;
            text-align: center;
        }
    }
}